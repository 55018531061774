$(function() {
	topFixed();
	$(window).resize(topFixed);
});

// top fixed
function topFixed() {
	const $notificationBar = $('.notification-bar');
	const $preHeader = $('.pre-header');
	const $header = $('.header');

	$notificationBar.css('transform', 'translateY(0)');

	$preHeader.css('top', $notificationBar.outerHeight() + 'px');
	$header.css(
		'margin-top',
		$preHeader.outerHeight() + ($notificationBar.outerHeight() || 0) + 'px'
	);
}
