// document.addEventListener('DOMContentLoaded', () => {

// });
$('.gallery').each(function(inx, gallery) {
	$(gallery)
		.find('[data-fancybox]')
		.fancybox({
			loop: true,
		});
});
