import 'jquery';
import 'nodelist-foreach-polyfill';
import 'date-input-polyfill';

// Copied from time-input-polyfill/auto as the included version has trailing
// commas and webpack won't transpile node_modules to es5 as it assumes thay're
// already transpiled.
var supports_time = require('time-input-polyfill/core/helpers/supportsTime');
var loadJS = require('time-input-polyfill/core/helpers/loadJS');

document.addEventListener('DOMContentLoaded', function() {
	if (!supports_time) {
		loadJS(
			'https://cdn.jsdelivr.net/npm/time-input-polyfill@1.0.7/dist/time-input-polyfill.min.js',
			function() {
				var $inputs = [].slice.call(
					document.querySelectorAll('input[type="time"]')
				);
				$inputs.forEach(function($input) {
					new TimePolyfill($input);
				});
			}
		);
	}
});

// Plugins
import 'mmenu-light';
import 'mmenu-light/dist/mmenu-light.polyfills';

// import 'mmenu-js';
import 'hamburgers';
import 'masonry-layout';
import 'imagesloaded';
import 'slick-carousel';
import '@fancyapps/fancybox';
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'respimage';
import 'jquery-validation';
import 'jquery-hoverintent';

// lib
import './lib/sticky-kit.min.js';
import './lib/filePond.js';

// modules
import './modules/bowser';
import './modules/svgIconSprite';
import './modules/masonry'; /* Display news & event list */
import './modules/stickyMenu'; /* Sticky Menu */
import './modules/topFixed'; /* Fixed Header */
import './modules/mobileMenu'; /* Mobile Menu - mmenu */
import './modules/header';
import './modules/slickCarousel';
import './modules/leaveCalendar';
import './modules/form';
import './modules/formValidation';
import './modules/imageGallery';
import './modules/peopleSearch';
import './modules/table';
// import './modules/tooltip';

/**
 * Accept hot reloading from dev server
 * Without this you may see this ming in your console:
 * "[HMR] Cannot apply update. Need to do a full reload!"
 */
if (module.hot) module.hot.accept();
