$(function() {
	const defaultOpt = {
		errorElement: 'span',
	};
	$('.js-validate').each(function(inx, form) {
		$(form).validate(
			Object.assign({}, defaultOpt, customRulesAndMessages(form))
		);
	});
});

function customRulesAndMessages(form) {
	// https://johnnycode.com/2014/03/27/using-jquery-validate-plugin-html5-data-attribute-rules/
	// Sample

	// if ($(form).hasClass('js-leave-request')) {
	// 	return {
	// 		rules: {
	// 			'leaveRequest[leaveType]': {
	// 				required: true,
	// 			},
	// 		},
	// 	};
	// }

	return {};
}
