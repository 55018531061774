// Mobile menu

document.addEventListener('DOMContentLoaded', () => {
	const node = document.querySelector('#mobile-menu');

	if (!node) return;

	const menu = new MmenuLight(node, {
		slidingSubmenus: true,
	});

	const trigger = document.querySelector('#toggleMobMenu');

	menu.enable('(max-width: 1024px)');
	menu.offcanvas({
		position: 'right',
		move: true,
		blockPage: true,
	});

	menu.close(true);

	node.addEventListener('mm:close', ev => {
		const hamburger = document.querySelector('.hamburger');
		hamburger.classList.remove('is-active');
	});

	document.querySelector('#toggleMobMenu').addEventListener('click', evnt => {
		menu.open();

		const hamburger = document.querySelector('.hamburger');
		hamburger.classList.add('is-active');

		// Don't forget to "preventDefault" and to "stopPropagation".
		evnt.preventDefault();
		evnt.stopPropagation();
	});
});
