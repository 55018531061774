$(window).on('load', function() {
	stickySetup();
	$(window).resize(stickySetup);
	initSubmenu();
});

function initSubmenu() {
	const $subMenu = $('.js-sticky-nav');
	$subMenu.on('click', '.sticky-nav__item--has-children .icon', function(evt) {
		evt.preventDefault();
		const $li = $(this).closest('.sticky-nav__item--has-children');
		const isOpen = $li.hasClass('sticky-nav__item--open');

		$subMenu.find('.sticky-nav__item--open').removeClass('sticky-nav__item--open');

		if (!isOpen)
			$li.addClass('sticky-nav__item--open');

		//$li.toggleClass('sticky-nav__item--open');
	});

}

function stickySetup() {
	const $sticky = $('.sidebar__content');
	const $subMenu = $('.js-sticky-nav');
	const $stickyWrapper = $('.sidebar__sticky-wrapper');
	const $sideContentWrapper = $('.sidebar__content-wrapper');
	const $hiddenPanel = $('#sidebar-content-holder');
	const hasStickyInstance = $sticky.data('sticky_kit');

	if ($(window).width() >= 768) {
		// revert the sticky nav from the mobile placeholder
		if ($stickyWrapper.children().length == 0) {
			$subMenu.appendTo($stickyWrapper);
		}

		// revert the sidebar content
		if ($sideContentWrapper.children().length == 0) {
			$hiddenPanel
				.find('.container')
				.children()
				.appendTo($sideContentWrapper);
			$hiddenPanel.addClass('hide');
		}

		// if the content is too long, disable sticky
		const contentHeight = $('.sidebar__content').outerHeight();
		const notificationHeight = $('.notification-bar').outerHeight() || 0;
		const preHeaderHeight = $('.pre-header').outerHeight() || 0;
		const sidebarTopPadding = parseInt($('.sidebar__content').css('padding-top'));
		const offsetTop =
			46 - /* desired gap between preheader and sidebar */
			sidebarTopPadding +
			notificationHeight +
			preHeaderHeight;

		const maxHeight =
			$(window).height() -
			notificationHeight -
			preHeaderHeight -
			50;


		if (contentHeight > maxHeight) return;

		if (!hasStickyInstance) {
			$sticky
				.stick_in_parent({
					offset_top: offsetTop,
					parent: '.main-content',
					spacer: false,
				})
				.on('sticky_kit:stick', function(e) {})
				.on('sticky_kit:unstick', function(e) {})
				.on('sticky_kit:bottom', function(e) {
					$(e.target).css('bottom', 'auto');

					$(e.target).css(
						'top',
						$('.main-content').innerHeight() -
							$(e.target).outerHeight()
					);
				})
				.on('sticky_kit:unbottom', function(e) {});
		}
	} else {
		if (hasStickyInstance) {
			$sticky.trigger('sticky_kit:detach');
		}

		$subMenu.insertAfter($('.heading').eq(0));
		$sideContentWrapper
			.children()
			.appendTo($hiddenPanel.find('.container'));
		$hiddenPanel.removeClass('hide');
	}
}
