$(function() {
	$('table.table').each((i, table) => {
		OddOrEven($(table));
	});
});

export function OddOrEven($table) {
	const $visibleRows = $table.find('.table__row:visible')
		.filter((i, el) => { return $(el).css('opacity') !== '0';});

	$visibleRows.each((i, row) => {
		$(row)
			.removeClass('odd')
			.removeClass('even')
			.addClass(i % 2 == 0 ? 'odd' : 'even');
	});
}
