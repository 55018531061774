const calendarAccordion = document.getElementsByClassName(
	'js-leave-calendar__accordion'
);
var i;

for (i = 0; i < calendarAccordion.length; i++) {
	calendarAccordion[i].addEventListener('click', function() {
		this.classList.toggle('active');
		const panel = this.nextElementSibling;
		if (panel.style.maxHeight) {
			panel.style.maxHeight = null;
		} else {
			panel.style.maxHeight = panel.scrollHeight + 'px';
		}
	});
}

// When clicking the 'TODAY' button, move to today's date
// Nick will change it.
$(function() {
	$(document).on('click', '.leave-calendar__dates .today', function() {
		const $list = $(this).closest('.leave-calendar__list');
		const $scrollArea = $list.find('.scroll-area');
		const $today = $list.find('.month .today');
		let left = $today.position().left + $scrollArea.scrollLeft();
		left -= 1 /*day*/ * 40; /*pixel*/
		//$scrollArea.scrollLeft(Math.max(left, 0))
		$scrollArea.animate({ scrollLeft: Math.max(left, 0) }, 500);
	});

	const scrollHandler = (offset, threshold) =>
		function() {
			const $list = $(this).closest('.leave-calendar__list');
			const $scrollArea = $list.find('.scroll-area');
			const $today = $list.find('.month');

			const scrollLeft = $scrollArea.scrollLeft();

			const positions = $today.get().map(ele => {
				const left = $(ele).position().left;
				return {
					ele,
					left,
				};
			});

			let firstVisibleI = positions.findIndex(
				pos => pos.left >= threshold
			);

			firstVisibleI += offset;
			if (firstVisibleI <= 0) {
				// FIXME: ajax previous month in .then scroll
			} else if (firstVisibleI >= positions.length) {
				// FIXME: ajax next month in .then scroll
			}

			// If not waiting for ajax {
			if (firstVisibleI >= 0 && firstVisibleI < positions.length) {
				$scrollArea.animate(
					{
						scrollLeft: Math.max(
							positions[firstVisibleI].left + scrollLeft + 2,
							0
						),
					},
					500
				);
			}
		};
	$('.leave-calendar__dates .nav-left').click(scrollHandler(-1, -2));
	$('.leave-calendar__dates .nav-right').click(scrollHandler(0, 2));
});
