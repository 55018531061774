$(document).ready(function() {
	$(window).scroll(function() {
		const scrollTop = $(window).scrollTop();
		const preHeader = $('.pre-header');
		if (scrollTop > 10) preHeader.addClass('scrolled');
		else preHeader.removeClass('scrolled');
	});

	// Hover intent for Mega menu
	$('.main-nav__list li').hoverIntent({
		over: function() {
			const $li = $(this);
			setSubnavIndicator($li);
			$li.addClass('active');
		},
		out: function() {
			const $li = $(this);
			$li.removeClass('active');
		},
		timeout: 200,
	});

	function setSubnavIndicator($li) {
		const menuLeft = $li.offset().left;
		const innerWrapper = $li.find('.sub-nav .inner-wrapper');
		if (innerWrapper.length === 0) {
			return;
		}
		const innerWrapperLeft = innerWrapper.offset().left;
		const indicatorLeft = menuLeft + $li.width() / 2 - innerWrapperLeft;
		$li.find('.before').css('left', indicatorLeft + 'px');
	}

	// Find someone form
	$('.action-bar__btn').click(function(ev) {
		$('.action-bar__form, .action-bar__btn').toggleClass('active');
		$('.pre-header__item').removeClass('active');

		ev.stopPropagation();
	});

	//  Close peope search form or profile submenu when clicking outside elsewhere on the page
	$(document).click(function(ev) {
		$('.action-bar__form').removeClass('active');
		$('.pre-header__item').removeClass('active');
	});

	$('.action-bar__form').click(function(ev) {
		ev.stopPropagation();
	});

	$('.pre-header__user-functions').click(function(ev) {
		ev.stopPropagation();
	});

	// Display profile submenu
	$('.pre-header__link--user').click(function(e) {
		$(this)
			.closest('li')
			.toggleClass('active');
		e.preventDefault();
	});

	// fine tuning the location of the submenu
	function relocateSubmenu() {
		const $li = $('.pre-header__link--user').closest('li');

		$li.find('ul').css('top', $li.outerHeight() + 'px');
	}

	$(window).resize(relocateSubmenu);
	relocateSubmenu();

	// Change breadcrumbs' bg colour depend on the next panel's bg colour
	const $breadcrumbs = $('.breadcrumbs');
	const $nextPanel = $('.js-main .panel:eq(0)');
	const classList = ($nextPanel.attr('class') || '').split(/\s+/);

	classList.forEach(function(className) {
		if (className.indexOf('bg--') >= 0) {
			$breadcrumbs.addClass(className);
		}
	});
});
