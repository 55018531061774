// https://www.npmjs.com/package/slick-carousel

$(document).ready(function() {
	$('.shindigs__slider').slick({
		dots: false,
		autoplay: true,
		autoplaySpeed: 5000,
		infinite: false,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
		easing: 'easeOutElastic',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
				},
			},

			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
				},
			},

			{
				breakpoint: 600,
				settings: 'unslick',
			},
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		],
	});

	$('.gallery__slider').slick({
		dots: false,
		autoplay: true,
		autoplaySpeed: 2000,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
		easing: 'easeOutElastic',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
				},
			},

			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
				},
			},

			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
				},
			},
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		],
	});
});
