jQuery($ => {
	$('.search-people__form').each((_, form) => {
		const $form = $(form);
		const $brand = $form.find('[name=brand]');
		const $region = $form.find('[name=region]');

		$brand.on('change', () => {
			if ($brand.val() !== '') {
				$region.val('').change();
			}
		});

		$region.on('change', () => {
			if ($region.val() !== '') {
				$brand.val('').change();
			}
		});

		$form.find('input, select').each((_, ele) => {
			const $ele = $(ele);

			$ele.attr('data-name', $ele.attr('name'));
			$ele.on('change', () => {
				if ($ele.val() == '') {
					$ele.removeAttr('name');
				} else {
					$ele.attr('name', $ele.attr('data-name'));
				}
			}).change();
		});
	});
});
