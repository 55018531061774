document.addEventListener('DOMContentLoaded', () => {

	$('.filepond').each(function() {
		const $this = $(this);
		const options = {
			allowMultiple: false,
			labelButtonRemoveItem: true,
		};

		const url = $this.attr('data-source');
		if (url) {
			options.files = [{ source: url }];
		}
		$this.filepond(options);
	});

	//$('.filepond').filepond();

	const forms = document.querySelectorAll('.form');

	forms.forEach(form => {
		const textInputs = form.querySelectorAll(
			'input[type="text"],' +
				'input[type="email"],' +
				'textarea,' +
				'input[type="password"],' +
				'input[type="tel"],' +
				'input[type="date"],' +
				'input[type="time"],' +
				'input[type="number"]'
		);
		textInputs.forEach(input => {
			checkTextInputValue(input);
			attachTextInputEventHandler(input);
		});
		const fileInputs = form.querySelectorAll('input[type="file"]');
		fileInputs.forEach(input => {
			attachFileSelectedHandler(input);
		});
	});

	$('input[type="tel"]').on('change input', function(e) {
		const val = $(this).val() || '';

		const safe = val.replace(/[^0-9 ()+#]/gu, '');

		if (val !== safe) {
			$(this)
				.val(safe)
				.trigger(e.type);
			e.preventDefault();
			return false;
		}
	});
});

function attachTextInputEventHandler(input) {
	input.addEventListener('focus', e => {
		const formField = e.target.parentElement;
		formField.classList.add('focus');
	});
	input.addEventListener('blur', e => {
		const formField = e.target.parentElement;
		formField.classList.remove('focus');
		checkTextInputValue(input);
	});
}

function checkTextInputValue(input) {
	if (input.value) {
		input.parentElement.classList.add('has-value');
	} else {
		input.parentElement.classList.remove('has-value');
	}
}

function attachFileSelectedHandler(input) {
	//var label = input.nextElementSibling, labelVal = label.innerHTML;
	const label = input.parentElement.querySelector('label');

	input.addEventListener('change', e => {
		let selectedFileName = '';
		const fileInput = e.target;
		if (fileInput.files && fileInput.files.length > 1) {
			// multiple files selected, use multiple-caption data tag if given
			selectedFileName = (
				fileInput.getAttribute('data-multiple-caption') || ''
			).replace('{count}', fileInput.files.length);
		} else {
			// single file, get the filename only from the full path
			selectedFileName = fileInput.value
				.split('\\')
				.pop()
				.split('/')
				.pop();
		}

		label.parentElement.querySelector(
			'.selected-file'
		).innerHTML = selectedFileName;
	});
}

/**
 * Clearable text inputs
 */
$('.clearable').each(function() {

	const $input = $(this).find('[type=text], [type=search]');
	const $clear = $(this).find('.clearable__clear');

	$input.on('input', function() {
		if (this.value) {
			$clear.addClass('show');
		} else {
			$clear.removeClass('show');
		}
	});

	$clear.on('touchstart click', function(e) {
		e.preventDefault();
		$input.val('').trigger('input');
	});

});
